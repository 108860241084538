<template>
  <MovieHead :movie />

  <PageHeaderSlideIn
    v-if="movie"
    :image="movie.heroImage"
    :title="movie.title"
  />

  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <MovieInfo :movie :show-cast="!showCastGallery" />
      </PageGridColumn>

      <PageGridColumn v-if="showCastGallery">
        <LazyMovieCast :movie />
      </PageGridColumn>

      <PageGridColumn class="z-10">
        <MovieCinemaListWithFilters :movie />
      </PageGridColumn>

      <PageGridColumn v-if="movie?.critique">
        <MovieCritique :movie />
      </PageGridColumn>
      <PageGridColumn>
        <ClientOnly>
          <LazyAdSlot
            v-if="isGooglePublisherTagConsentGiven"
            v-bind="appConfig.adSlots.external"
            :slot-name="AD_SLOT_NAME.EXTERNAL"
          />
        </ClientOnly>
      </PageGridColumn>

      <PageGridColumn v-if="movie.genres?.length" class="mt-8 md:mt-12">
        <LazyMovieRelatedMovies :movie />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import { AD_SLOT_NAME } from '@entradas/constants/ads'
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

const { movie } = defineProps<Props>()
const { showCastGallery } = useShowCastGallery(movie)
const { $gtm } = useNuxtApp()
const { t } = useI18n()

onMounted(() => {
  $gtm.trackPageView()
})

usePageMetas({
  title: `${t('view_movie', {
    movie: movie.title,
  })} | ${t('tickets')}`,
  ...movie.meta,
})

const appConfig = useAppConfig()
const { getConsentForPurpose } = useConsentManager()
const isGooglePublisherTagConsentGiven = computed(() =>
  getConsentForPurpose(CONSENT_MANAGER_PURPOSE.ADVERTISING)
)

defineOptions({
  name: 'GenericPageMovie',
})
</script>
<i18n>
de:
  view_movie: "{movie} ansehen"
  tickets: "Kinotickets"
es:
  view_movie: "Ver {movie}"
  tickets: "Entradas, cines y fechas"
</i18n>
